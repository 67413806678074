var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-row',[_c('b-col',{staticClass:"pr-md-0 border-right",staticStyle:{"min-height":"calc(100vh - 173px)"},attrs:{"md":"4","lg":"3"}},[_c('div',{staticClass:"user-list"},[_c('b-input-group',{staticClass:"input-group-merge p-1"},[_c('b-input-group-prepend',{attrs:{"is-text":""}},[_c('feather-icon',{attrs:{"icon":"SearchIcon"}})],1),_c('b-form-input',{attrs:{"placeholder":_vm.$t('common_search')},on:{"input":function($event){return _vm.searchRole()}},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}})],1),_c('app-collapse',_vm._l((_vm.listRole),function(role){return _c('app-collapse-item',{key:role.GroupUserID,attrs:{"title":role.Keyword +
              '(' +
              _vm.listUserFilter.filter(function (x) { return x.GroupUserId == role.GroupUserID; })
                .length +
              ')',"isVisible":true}},_vm._l((_vm.listUserFilter.filter(
                function (x) { return x.GroupUserId == role.GroupUserID; }
              )),function(user){return _c('b-media',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],key:user.UserId,staticClass:"px-1 py-50 cursor-pointer",class:[
                _vm.currentUserData.UserId == user.UserId ? 'active' : '',
                user.UserSiteStatus == 'WAITING' ? 'waiting' : '',
                user.UserSiteStatus == 'DEACTIVE' ? 'deactive' : '' ],attrs:{"vertical-align":"top","id":'tooltip-target-' + user.UserId,"title":user.UserSiteStatus == 'DEACTIVE'
                  ? _vm.$t('user_management_user_status_deactive')
                  : user.UserSiteStatus == 'WAITING'
                  ? _vm.$t('user_management_user_status_waiting')
                  : ''},on:{"click":function($event){return _vm.getDetailUser(user)}},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"39px","src":user.Avatar}})]},proxy:true}],null,true)},[_c('h5',{staticClass:"text-truncate"},[_vm._v(_vm._s(user.FullName))]),_c('b-card-text',{staticClass:"mb-0 text-truncate"},[_vm._v(_vm._s(user.Email))])],1)}),1)}),1),(_vm.isCanAddUser)?_c('div',{staticClass:"p-1 text-primary cursor-pointer invite-user",on:{"click":function($event){return _vm.openModalInvite()}}},[_c('ez-icon',{staticClass:"pr-50",attrs:{"icon":"ez_add_circle_outline","size":"14"}}),_vm._v(" "+_vm._s(_vm.$t('user_management_user_invite_user'))+" ")],1):_vm._e()],1)]),(_vm.currentUserData && _vm.currentUserData.UserId)?_c('b-col',{staticClass:"pl-md-0",attrs:{"md":"8","lg":"6"}},[_c('div',{staticClass:"py-1 px-1"},[_c('b-row',{staticClass:"form-row",attrs:{"align-v":"center"}},[_c('b-col',{attrs:{"md":"7"}},[_c('b-media',{attrs:{"vertical-align":"top"},scopedSlots:_vm._u([{key:"aside",fn:function(){return [_c('b-avatar',{attrs:{"size":"50px","src":_vm.currentUserData.Avatar}})]},proxy:true}],null,false,2637919787)},[_c('h4',{staticClass:"text-truncate"},[_vm._v(_vm._s(_vm.currentUserData.FullName))]),_c('b-card-text',{staticClass:"mb-0 text-truncate"},[_vm._v(_vm._s(_vm.currentUserData.Email))])],1)],1),(_vm.currentUserID != _vm.currentUserData.UserId)?_c('b-col',{staticClass:"text-right"},[(_vm.isCanEditUser)?_c('b-button',{attrs:{"variant":"primary","type":"submit","disabled":_vm.isLoading},on:{"click":_vm.updateUser}},[_vm._v(" "+_vm._s(_vm.$t('common_update'))+" ")]):_vm._e(),(_vm.isCanEditUser)?_c('b-button',{staticClass:"text-primary btn-icon ml-1",attrs:{"variant":"flat-primary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){_vm.currentUserData.UserSiteStatus == 'ACTIVE' ||
                _vm.currentUserData.UserSiteStatus == 'WAITING'
                  ? _vm.deActiveUser()
                  : _vm.activeUser()}}},[_c('ez-icon',{staticClass:"cursor-pointer",attrs:{"icon":_vm.currentUserData.UserSiteStatus == 'ACTIVE' ||
                  _vm.currentUserData.UserSiteStatus == 'WAITING'
                    ? 'ez_pause_circle_outline'
                    : 'ez_play_circle_outline',"size":"21","color":"#114A9F"}})],1):_vm._e(),(_vm.isCanDeleteUser)?_c('b-button',{staticClass:"text-primary btn-icon ml-1",attrs:{"variant":"flat-primary","size":"sm","disabled":_vm.isLoading},on:{"click":function($event){return _vm.deleteUser()}}},[_c('ez-icon',{staticClass:"cursor-pointer",attrs:{"icon":"ez_delete_outline","size":"21","color":"#EA5455"}})],1):_vm._e()],1):_vm._e()],1),_c('validation-observer',{ref:"updateUser"},[_c('b-form',{staticClass:"pt-1 form-row"},[_c('b-col',{attrs:{"cols":"8"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user_management_role_group'),"label-for":"role-group"}},[_c('validation-provider',{attrs:{"name":_vm.$t('user_management_role_group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                    var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"Keyword","reduce":function (Keyword) { return Keyword.GroupUserID; },"options":_vm.listRole,"clearable":false,"state":errors.length > 0 ? false : null,"disabled":!_vm.isCanEditUser ||
                      _vm.currentUserID == _vm.currentUserData.UserId
                        ? true
                        : false},model:{value:(_vm.currentUserData.GroupUserId),callback:function ($$v) {_vm.$set(_vm.currentUserData, "GroupUserId", $$v)},expression:"currentUserData.GroupUserId"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,896623901)})],1)],1),_c('b-col',{attrs:{"cols":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('user_management_user_expire_date'),"label-for":"expire-date"}},[_c('ez-date-picker',{key:_vm.currentUserData.UserId,ref:"picker",attrs:{"placeholder":_vm.$t('user_management_user_expire_date'),"opens":"right","locale-data":{
                    firstDay: 1,
                    format: 'dd-mm-yyyy',
                  },"timePicker":false,"timePicker24Hour":false,"showWeekNumbers":false,"showDropdowns":false,"autoApply":true,"appendToBody":false,"disabled":!_vm.isCanEditUser || _vm.currentUserID == _vm.currentUserData.UserId
                      ? true
                      : false,"minDate":_vm.minDate,"isInputMask":false,"isCanClear":true},model:{value:(_vm.currentUserData.ExpireDate),callback:function ($$v) {_vm.$set(_vm.currentUserData, "ExpireDate", $$v)},expression:"currentUserData.ExpireDate"}})],1)],1)],1)],1)],1)]):_c('b-col',{staticClass:"d-flex justify-content-center align-items-center flex-column"},[_c('b-img',{attrs:{"src":require('@/assets/images/select-user.png')}}),_c('p',{staticClass:"pt-1"},[_vm._v(" "+_vm._s(_vm.$t('user_management_user_please_select_user_to_view_detail'))+" ")])],1)],1),(_vm.isCanAddUser)?_c('b-modal',{attrs:{"size":"sm","no-close-on-backdrop":true,"centered":""},scopedSlots:_vm._u([{key:"modal-header",fn:function(ref){
                      var close = ref.close;
return [_c('h4',{staticClass:"text-primary mb-0"},[_vm._v(" "+_vm._s(_vm.$t('user_management_user_invite_user'))+" ")]),_c('ez-icon',{staticClass:"cursor-pointer text-primary",attrs:{"icon":"ez_close","size":"24"},on:{"click":function($event){return close()}}})]}},{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"flat-primary"},on:{"click":function($event){_vm.isOpenModalInvite = false}}},[_vm._v(" "+_vm._s(_vm.$t('common_back'))+" ")]),_c('b-button',{staticClass:"mr-0",attrs:{"variant":"primary","disabled":_vm.isLoading},on:{"click":function($event){return _vm.sendInvite()}}},[_vm._v(" "+_vm._s(_vm.$t('user_management_user_send_invite')))])]},proxy:true}],null,false,3229105642),model:{value:(_vm.isOpenModalInvite),callback:function ($$v) {_vm.isOpenModalInvite=$$v},expression:"isOpenModalInvite"}},[_c('div',[_c('validation-observer',{ref:"inviteUser"},[_c('b-form',{staticClass:"pt-1 form-row"},[_c('b-col',{attrs:{"md":"7"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('common_email_address'),"rules":"required|email|max:150"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":_vm.$t('common_email_address'),"state":errors.length > 0 ? false : null},model:{value:(_vm.modalInvite.Email),callback:function ($$v) {_vm.$set(_vm.modalInvite, "Email", $$v)},expression:"modalInvite.Email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3492350293)})],1)],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('user_management_role_group'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('v-select',{attrs:{"placeholder":_vm.$t('user_management_role_group'),"label":"Keyword","reduce":function (Keyword) { return Keyword.GroupUserID; },"options":_vm.listRole,"clearable":false,"state":errors.length > 0 ? false : null},model:{value:(_vm.modalInvite.GroupUserID),callback:function ($$v) {_vm.$set(_vm.modalInvite, "GroupUserID", $$v)},expression:"modalInvite.GroupUserID"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2451878578)})],1)],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',[_c('validation-provider',{attrs:{"name":_vm.$t('common_message'),"rules":"max:200"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                      var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"placeholder":_vm.$t('common_message')},model:{value:(_vm.modalInvite.Messages),callback:function ($$v) {_vm.$set(_vm.modalInvite, "Messages", $$v)},expression:"modalInvite.Messages"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2125792582)})],1)],1)],1)],1)],1)]):_vm._e(),(_vm.isCanEditUser)?_c('ez-confirm-modal',{ref:"confirmDeactive",attrs:{"title":_vm.$t('user_management_user_confirm_deactive'),"placeholder":_vm.$t('user_management_user_deactive_reason'),"label":_vm.$t('user_management_user_deactive_reason'),"variant":"confirm"},on:{"handle-confirm":_vm.confirmDeactive}}):_vm._e(),(_vm.isCanEditUser)?_c('ez-confirm-modal',{ref:"confirmActive",attrs:{"title":_vm.$t('user_management_user_confirm_active'),"placeholder":_vm.$t('user_management_user_are_you_sure_active'),"label":_vm.$t('user_management_user_are_you_sure_active'),"variant":"confirm-no-reason","noReason":true},on:{"handle-confirm":_vm.confirmActive}}):_vm._e(),(_vm.isCanDeleteUser)?_c('ez-confirm-modal',{ref:"confirmDelete",attrs:{"title":_vm.$t('user_management_user_confirm_delete'),"placeholder":_vm.$t('user_management_user_delete_reason'),"label":_vm.$t('user_management_user_delete_reason'),"variant":"delete"},on:{"handle-confirm":_vm.confirmDelete}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { mapGetters, mapState } from 'vuex';
import { UserActionsCode } from '@/@core/data/enum/menuCode';

import {
  getAccountListApi,
  inviteUserApi,
  updateUserApi,
  deleteUserApi,
  updateStatusUserApi,
} from '@/api/action-defines/user-management';

import dayjs from 'dayjs';
var customParseFormat = require('dayjs/plugin/customParseFormat');
dayjs.extend(customParseFormat);

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required } from '@validations';

import { showToast, removeAccents } from '@/@core/utils/utils';

export default {
  name: 'UserList',
  components: {
    AppCollapse,
    AppCollapseItem,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      isOpenModalInvite: false,
      modalInvite: {
        Email: '',
        GroupUserID: '',
        Messages: '',
      },
      listUser: [],
      listUserFilter: [],
      currentUserData: {
        UserId: null,
      },
      required,
      isLoading: false,
      keyword: '',
      minDate: null,
    };
  },
  computed: {
    ...mapState({
      currentSiteID: (state) => state.auth.user.SiteID,
      currentUserID: (state) => state.auth.user.UserID,
      groupUsers: (state) => state.app.resources.GroupUsers,
    }),
    ...mapGetters({
      $lsAllowOperation: 'app/allowOperation',
      $lsGet_userSiteGroupRoleInfo: 'app/userSiteGroupRoleInfo'
    }),
    $ls_CurrentUserSiteGroupRoleInfo() {
      return this.$lsGet_userSiteGroupRoleInfo(this.currentSiteID);
    },
    $ls_IsSystemAccount() {
      if (this.$ls_CurrentUserSiteGroupRoleInfo) {
        return ['SystemSupport', 'SystemAdmin'].includes(this.$ls_CurrentUserSiteGroupRoleInfo.GroupRoleCode);
      }
    },
    isCanAddUser() {
      return this.$lsAllowOperation(UserActionsCode.ADD);
    },
    isCanEditUser() {
      return this.$lsAllowOperation(UserActionsCode.EDIT);
    },
    isCanDeleteUser() {
      return this.$lsAllowOperation(UserActionsCode.DELETE);
    },
    listRole() {
      return this.groupUsers.map((x) => {
        return { GroupUserID: x.GroupUserID, Keyword: this.$t(x.Keyword) };
      });
    },
  },
  created() {
    if (!this.$ls_IsSystemAccount) {
      this.minDate = new Date();
    }
    this.getAccountList();
  },
  methods: {
    getAccountList() {
      getAccountListApi().then((res) => {
        this.listUser = res.Data;
        this.listUserFilter = res.Data;
      });
    },
    async getDetailUser(userData) {
      this.isLoading = true;

      this.currentUserData = { ...userData };
      if (this.currentUserData.ExpireDate) {
        this.currentUserData.ExpireDate = new Date(
          this.currentUserData.ExpireDate
        );
      }
      this.isLoading = false;
      if (this.$refs.updateUser) {
        this.$refs.updateUser.reset();
      }
    },
    async updateUser() {
      this.isLoading = true;
      this.$refs.updateUser.validate().then(async (success) => {
        if (success) {
          let BodyData = {
            UserID: this.currentUserData.UserId,
            GroupUserID: this.currentUserData.GroupUserId,
            ExpireDate: this.currentUserData.ExpireDate,
          };
          await updateUserApi({ BodyData: BodyData })
            .then((res) => {
              if (res.IsSuccess) {
                this.getAccountList();
                showToast('success', this.$t('common_success'));
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    openModalInvite() {
      this.isOpenModalInvite = true;
      // Reset data form
      this.modalInvite = {
        Email: '',
        GroupUserID: this.listRole[0].GroupUserID,
        Messages: '',
      };
    },
    async sendInvite() {
      this.$refs.inviteUser.validate().then(async (success) => {
        if (success) {
          this.isLoading = true;
          await inviteUserApi({ BodyData: this.modalInvite })
            .then((res) => {
              if (res.IsSuccess) {
                this.getAccountList();
                this.isOpenModalInvite = false;
                showToast('success', this.$t('common_success'));
              }
            })
            .finally(() => {
              this.isLoading = false;
            });
        }
      });
    },
    activeUser() {
      this.$refs.confirmActive.isOpen = true;
    },
    confirmActive() {
      this.updateUserStatus('ACTIVE');
    },
    deActiveUser() {
      this.$refs.confirmDeactive.isOpen = true;
    },
    confirmDeactive(reason) {
      this.updateUserStatus('DEACTIVE', reason);
    },
    deleteUser() {
      this.$refs.confirmDelete.isOpen = true;
    },
    async confirmDelete(reason) {
      this.isLoading = true;
      let BodyData = {
        UserID: this.currentUserData.UserId,
        ReasonDelete: reason,
      };
      await deleteUserApi({ BodyData: BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.getAccountList();
            this.$refs.confirmDelete.isOpen = false;
            this.currentUserData.UserId = null;
            showToast('success', this.$t('common_success'));
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    async updateUserStatus(status, reason = null) {
      this.isLoading = true;
      let BodyData = {
        UserID: this.currentUserData.UserId,
        Status: status,
      };
      if (status == 'DEACTIVE') {
        BodyData.ReasonDeactive = reason;
      }

      await updateStatusUserApi({ BodyData: BodyData })
        .then((res) => {
          if (res.IsSuccess) {
            this.getAccountList();
            this.currentUserData.UserSiteStatus = status;
            showToast('success', this.$t('common_success'));
            if (status == 'DEACTIVE') {
              this.$refs.confirmDeactive.isOpen = false;
            } else {
              this.$refs.confirmActive.isOpen = false;
            }
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    searchRole() {
      if (this.keyword) {
        this.listUserFilter = [];
        this.listUser.forEach((x) => {
          if (
            removeAccents(x.FullName)
              .split(' ')
              .join('')
              .toUpperCase()
              .includes(
                removeAccents(this.keyword).split(' ').join('').toUpperCase()
              )
          ) {
            this.listUserFilter.push(x);
          }
        });
      } else {
        this.listUserFilter = this.listUser;
      }
    },
  },
};
